.contact-page {
  padding: 80px 0;
  background-color: #fff;
}

.contact-content {
  display: flex;
  align-items: center;
  gap: 60px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.contact-text {
  flex: 1;
}

.contact-text h1 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 30px;
  background: linear-gradient(to right, #2d2d2d, #666);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-text p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #666;
  margin-bottom: 20px;
}

.contact-image {
  flex: 1;
  max-width: 500px;
}

.contact-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.contact-buttons {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.contact-button {
  padding: 16px 28px;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  background: #fff;
  color: #007bff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.contact-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #2d2d2d, #666);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.contact-button:hover:before {
  opacity: 1;
}

.contact-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  color: #007bff;
}

.contact-button:hover .button-description {
  color: #007bff;
}

.button-description {
  font-size: 0.85rem;
  font-weight: 400;
  margin-top: 5px;
  opacity: 0.8;
  position: relative;
  z-index: 2;
}

.contact-button span {
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
}

@media (max-width: 480px) {
  .contact-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .contact-button {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .contact-page {
    padding: 60px 0;
  }

  .contact-text h1 {
    font-size: 2rem;
  }

  .contact-text p {
    font-size: 1rem;
  }
}