.networking-products {
  min-height: 100vh;
  padding: 0;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 50px;
}

.solutions-content {
  margin-top: 40px;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

p {
  color: #666;
  line-height: 1.6;
  font-size: 18px;
}

.hero-grid {
  min-height: 80vh;
  background-color: #f8f9fa;
}

.categories-section {
  padding: 100px 0;
  background: #f8f9fa;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 50px;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  padding: 20px;
}

.category-card {
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-color: #007bff;
}

.category-card h3 {
  color: #007bff;
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
  padding-bottom: 15px;
}

.category-card h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background: #007bff;
  border-radius: 2px;
}

.category-card p {
  color: #666;
  line-height: 1.6;
  font-size: 1rem;
  margin: 0;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 30px;
}

.search-box input {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  padding: 20px 0;
}

/* Hero Image Grid Styles */
.hero-image-grid {
  padding: 1rem;
  background: #ffffff;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 400px);
  gap: 1rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 1.5rem;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Top row */
.grid-item:nth-child(1) {
  grid-column: 1 / span 2; /* Rectangle taking 2/3 width */
}

.grid-item:nth-child(2) {
  grid-column: 3 / span 1; /* Square taking 1/3 width */
}

/* Bottom row */
.grid-item:nth-child(3) {
  grid-column: 1 / span 1; /* Square taking 1/3 width */
}

.grid-item:nth-child(4) {
  grid-column: 2 / span 2; /* Rectangle taking 2/3 width */
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.grid-item:hover .grid-image {
  transform: scale(1.05);
}

/* Add a subtle overlay on hover */
.grid-item::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.grid-item:hover::after {
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .grid-container {
    grid-template-rows: repeat(2, 300px);
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 250px);
  }

  .grid-item:nth-child(n) {
    grid-column: 1 / -1; /* All items take full width on mobile */
  }

  .hero-image-grid {
    padding: 0.5rem;
  }
}

.hero-title-section {
  padding: 2rem 0 2rem;
  background: #ffffff;
  text-align: center;
}

.hero-title-section h1 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1.5rem;
}

.hero-description {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
  padding: 0 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-title-section {
    padding: 3rem 0 1.5rem;
  }

  .hero-title-section h1 {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1.1rem;
  }
}

/* Add these styles to your existing CSS */

.loading, .error, .no-results {
  text-align: center;
  padding: 2rem;
  grid-column: 1 / -1;
  color: #666;
}

.error {
  color: #dc3545;
}

.product-card {
  cursor: pointer;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product-card:hover .product-image img {
  transform: scale(1.05);
}

.product-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.product-content h3 {
  font-size: 1rem;
  color: #333;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.product-content p {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.product-type {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #f8f9fa;
  border-radius: 20px;
  font-size: 0.8rem;
  color: #666;
  align-self: flex-start;
}

.info-link {
  margin-top: auto;
  color: #007bff;
  font-size: 0.9rem;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  align-self: flex-start;
  padding: 0.25rem 0;
}

.info-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #007bff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.info-link:hover {
  color: #0056b3;
}

.info-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Remove the old know-more-btn styles */
.know-more-btn {
  display: none;
}

/* Adjust the products grid layout */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 1.5rem 0;
  }
}

/* Add new modal styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.product-modal {
  background: white;
  border-radius: 15px;
  width: 100%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 2rem;
}

.modal-left {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.modal-left h2 {
  font-size: 2rem;
  color: #333;
  margin: 0;
}

.modal-image {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.modal-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.modal-right {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.full-description {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.description-point {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.bullet {
  color: #007bff;
  font-size: 1.2rem;
  line-height: 1.5;
}

.description-point p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  flex: 1;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.close-modal:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .modal-content {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1.5rem;
  }

  .modal-left h2 {
    font-size: 1.5rem;
  }

  .full-description {
    font-size: 1rem;
  }
}