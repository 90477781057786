.hiring-page {
  padding: 80px 0;
  background-color: #fff;
}

.hiring-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.hiring-text {
  text-align: center;
  margin-bottom: 40px;
}

.hiring-text h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  background: linear-gradient(to right, #2d2d2d, #666);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hiring-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
}

.hiring-form-container {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.hiring-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

.form-group input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}

.form-group input.error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 4px;
}

.submit-button {
  background: #007bff;
  color: #fff;
  padding: 14px 28px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.submit-button:hover {
  background: #0056b3;
}

.submit-status {
  text-align: center;
  padding: 12px;
  border-radius: 6px;
  margin-top: 20px;
}

.submit-status.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.submit-status.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@media (max-width: 768px) {
  .hiring-page {
    padding: 60px 0;
  }

  .hiring-text h1 {
    font-size: 2rem;
  }

  .hiring-text p {
    font-size: 1rem;
  }

  .hiring-form-container {
    padding: 20px;
  }
}