.case-studies-container {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.case-studies-container h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
}

.case-studies-grid {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.case-study-card {
  display: flex;
  gap: 2rem;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.image-section {
  flex: 1;
  min-width: 400px;
}

.content-section {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.direction-aware-hover {
  height: 400px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-study-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: none;
  transition: none;
}

.direction-aware-hover:hover .case-study-image {
  transform: none;
}

.hover-overlay {
  display: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  transition: all 0.5s;
}

.direction-aware-hover:hover .hover-overlay {
  display: block;
}

.hover-content {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 40;
  color: #333;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 8px;
}

.hover-details h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #333;
}

.hover-details p {
  font-weight: 500;
  color: #555;
}

.read-more-btn {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.read-more-btn:hover {
  background: #0056b3;
}

.expanded-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.expanded-case-study {
  background: white;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  border-radius: 20px;
  position: relative;
  padding: 2rem;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-study-details {
  margin-top: 2rem;
}

.case-study-details h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1.5rem 0 1rem;
}

.locations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 1.5rem;
}

.location-card {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  transform: none;
}

.location-card h4 {
  padding: 1rem;
  margin: 0;
  font-size: 1.1rem;
  color: #333;
}

@media (max-width: 768px) {
  .case-study-card {
    flex-direction: column;
  }

  .image-section {
    min-width: 100%;
  }

  .direction-aware-hover {
    height: 300px;
  }
}