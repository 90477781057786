.solutions-container {
  min-height: 100vh;
  background: #fff;
  color: #000;
  padding: 4rem 2rem;
  max-width: 1800px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-x: hidden;
}

.solutions-title {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 4rem;
  padding: 0 2rem;
  text-align: left;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .solutions-container {
    padding: 2rem 1rem;
  }

  .solutions-title {
    font-size: 2.5rem;
    padding: 0 1rem;
  }

  .carousel-track {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  .card {
    width: 100%;
    max-width: 320px;
  }

  .expanded-card {
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

.solutions-section {
  margin-bottom: 4rem;
  position: relative;
  min-height: 700px;
}

.solutions-section:last-child {
  margin-bottom: 2rem;
}

.carousel-container {
  position: relative;
  width: 100%;
  padding: 2rem 0;
}

.carousel-track {
  display: flex;
  gap: 2rem;
  padding: 2rem 4rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  min-height: 650px;
}

.card {
  flex: 0 0 auto;
  width: 400px;
  height: 600px;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  scroll-snap-align: start;
  background: #f5f5f5;
}

.card-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, transparent 100%);
  z-index: 2;
}

.card-content {
  position: relative;
  z-index: 3;
  padding: 2rem;
  color: white;
}

.card-category {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: white;
}

.card-title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  color: white;
}

.card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.expanded-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
}

.expanded-card {
  background: white;
  color: black;
  border-radius: 24px;
  width: 95%;
  max-width: 1200px;
  max-height: 85vh;
  overflow-y: auto;
  padding: 4rem 3rem 3rem;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.expanded-card-content {
  max-width: 1000px;
  margin: 0 auto;
}

.expanded-content {
  margin-top: 2rem;
}

.content-overview {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #333;
}

.content-details {
  display: grid;
  gap: 2rem;
}

.detail-section {
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 12px;
}

.detail-section h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #000;
}

.detail-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.detail-section li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.detail-section li:last-child {
  border-bottom: none;
}

.detail-section p {
  line-height: 1.6;
  color: #666;
}

/* Custom scrollbar for expanded card */
.expanded-card::-webkit-scrollbar {
  width: 8px;
}

.expanded-card::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.expanded-card::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.expanded-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #000;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 10;
}

.carousel-button.left {
  left: 1rem;
}

.carousel-button.right {
  right: 1rem;
}

.close-button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.section-title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  padding: 0 2rem;
  color: #000;
}

.expanded-image {
  width: 100%;
  height: 500px;
  border-radius: 16px;
  overflow: hidden;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add styles for square images */
.expanded-image.square-image {
  height: auto;
  max-height: 800px;
  aspect-ratio: 1;
  margin: 2rem auto;
}

.expanded-image img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Changed from cover to contain for better square image handling */
}

.expanded-header {
  margin-bottom: 2rem;
  text-align: left;
}

.expanded-category {
  font-size: 1.1rem;
  font-weight: 500;
  color: #666;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.expanded-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #000;
  line-height: 1.2;
  margin: 0;
}

@media (max-width: 768px) {
  .solutions-title {
    font-size: 2.5rem;
  }

  .card {
    width: 320px;
    height: 500px;
  }

  .expanded-overlay {
    padding: 2rem 1rem;
  }

  .expanded-card {
    padding: 3rem 1.5rem 1.5rem;
    max-height: 80vh;
  }

  .detail-section {
    padding: 1.5rem;
  }

  .detail-section h4 {
    font-size: 1.25rem;
  }

  .solutions-section {
    margin-bottom: 4rem;
  }

  .section-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .close-button {
    top: 1rem;
    right: 1rem;
    width: 40px;
    height: 40px;
  }

  .expanded-image {
    height: 400px;
  }

  .expanded-image.square-image {
    max-height: 500px;
  }

  .expanded-title {
    font-size: 2rem;
  }
  
  .expanded-category {
    font-size: 1rem;
  }

  .carousel-track {
    min-height: 550px;
  }

  .solutions-section {
    min-height: 600px;
  }

  .category-container {
    border-radius: 24px;
    margin: 1.5rem 0;
  }
}

/* Add new category container styles */
.category-container {
  padding: 2rem 0;
  margin: 2rem 0;
  border-radius: 40px;
  overflow: hidden;
}

/* Update background colors for each main category */
.category-container.networking {
  background-color: #d4d8dd;  /* Even darker version */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.category-container.building {
  background-color: #c8ced6;  /* Even darker version */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.category-container.av-automation {
  background-color: #bcc5d0;  /* Even darker version */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Update section title for categories */
.category-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
  padding: 0 4rem;
  color: #000;
}

.category-card h3 {
  color: #007bff;
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
  padding-bottom: 15px;
  display: inline-block;
}

.category-card h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #007bff;
  border-radius: 2px;
}

.solutions-page {
  padding-top: 80px;
}

.hero-title-section {
  background: #f8f9fa;
  padding: 60px 0;
  text-align: center;
}

.hero-title-section h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.hero-description {
  max-width: 800px;
  margin: 0 auto;
  color: #666;
  font-size: 1.2rem;
  line-height: 1.6;
}

.solutions-section {
  padding: 80px 0;
  background: #fff;
}

.solutions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.solution-card {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.solution-card:hover {
  transform: translateY(-5px);
}

.solution-card h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.solution-card p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.benefits h4 {
  color: #12378f;
  margin-bottom: 1rem;
}

.benefits ul {
  list-style: none;
  padding: 0;
}

.benefits li {
  color: #555;
  padding: 8px 0;
  padding-left: 24px;
  position: relative;
}

.benefits li::before {
  content: "•";
  color: #4CAF50;
  font-weight: bold;
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .hero-title-section {
    padding: 40px 0;
  }

  .hero-title-section h1 {
    font-size: 2rem;
  }

  .solutions-section {
    padding: 40px 0;
  }
}