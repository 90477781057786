.blog-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.blog-hero {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 6rem 0;
  margin-bottom: 4rem;
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.blog-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 4rem;
  align-items: center;
  padding: 0 2rem;
}

.blog-hero-text {
  text-align: left;
}

.blog-hero-logo-small {
  font-size: 1.1rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
}

.blog-hero-text h1 {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 2rem;
  line-height: 1.1;
  background: linear-gradient(45deg, #1a365d, #2563eb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -1px;
}

.blog-hero-description {
  font-size: 1.25rem;
  color: #4a5568;
  line-height: 1.8;
  margin-bottom: 2rem;
  max-width: 500px;
}

.blog-grid {
  padding: 4rem;
  margin-bottom: 2rem;
  background: #fff;
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
}

.blog-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.blog-list-card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 450px;
}

.blog-list-card:hover {
  transform: translateY(-5px);
}

.blog-list-card-image {
  width: 100%;
  height: 220px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-list-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
  
 


.blog-list-card-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.blog-list-card-header {
  flex: 1;
}

.blog-list-category {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #e5e7eb;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: #4b5563;
  margin-bottom: 1rem;
}

.blog-list-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.75rem;
  line-height: 1.4;
}

.blog-list-description {
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.blog-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.blog-list-author {
  color: #4b5563;
  font-size: 0.875rem;
  font-weight: 500;
}

.blog-list-read-more {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: #2563eb;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-list-read-more:hover {
  background: #1d4ed8;
}

@media (max-width: 1024px) {
  .blog-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1.1rem;
  }

  .blog-cards {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
}

/* Update Slider Styles */
.slider-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  min-height: 500px;
}

.slider-frame {
  background: #f8f9fa;
  border-radius: 24px;
  padding: 40px;
  position: relative;
  height: 100%;
  overflow: hidden;
}

/* Update grid layout styles */
.slides-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
  transition: transform 0.5s ease-in-out;
}

/* Style for the third item */
.slide-item:last-child:nth-child(3) {
  grid-column: 1 / 3;
  width: calc(50% - 10px);
  margin: 0 auto;
  justify-self: center;
  height: 240px;
}

.slide-item {
  background: white;
  border-radius: 16px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  height: 240px;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s ease-in-out;
}

/* Update individual slide styles */
.slide-title {
  font-size: 16px;
  color: #333;
  margin: 0 0 12px 0;
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2;
}

.slide-description {
  color: #666;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 15px;
}

.slide-image {
  flex: 1;
  width: 100%;
  height: 150px;
  border-radius: 12px;
  overflow: hidden;
  margin-top: auto;
}

.slide-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-footer {
  display: none;
}

.favorite-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.favorite-btn svg {
  width: 24px;
  height: 24px;
  fill: #ddd;
  transition: fill 0.3s ease;
}

.favorite-btn:hover svg {
  fill: #ff4d4d;
}

/* Update responsive breakpoints */
@media (max-width: 1200px) {
  .blog-hero-content {
    grid-template-columns: 1fr 1fr;
  }
  
  .blog-hero-text h1 {
    font-size: 3rem;
  }
}

@media (max-width: 992px) {
  .blog-hero-content {
    grid-template-columns: 1fr;
  }

  .blog-hero-text {
    position: static;
    max-width: 100%;
    text-align: center;
    padding-right: 0;
    margin-bottom: 40px;
  }

  .slider-container {
    max-width: 600px;
    margin: 0 auto;
    min-height: auto;
  }
  
  .slides-grid {
    grid-template-columns: 1fr;
  }

  .slide-item,
  .slide-item:last-child:nth-child(3) {
    width: 100%;
    grid-column: auto;
  }
}

@media (max-width: 576px) {
  .slides-grid {
    grid-template-columns: 1fr;
  }

  .slide-item:last-child:nth-child(3) {
    grid-column: auto;
    width: 100%;
  }
}

/* Add new slider navigation styles */
.slider-nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  pointer-events: none;
  z-index: 10;
}

.nav-btn {
  background: white;
  border: none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  pointer-events: auto;
}

.nav-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.nav-btn svg {
  width: 24px;
  height: 24px;
  fill: #333;
}

.nav-btn.prev {
  left: 0;
}

.nav-btn.next {
  right: 0;
}

/* Add slide animation keyframes */
@keyframes slideLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Add classes for animations */
.slide-exit {
  animation: slideLeft 0.5s ease-in-out forwards;
}

.slide-enter {
  animation: slideRight 0.5s ease-in-out forwards;
}
