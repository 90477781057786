.about-page {
  min-height: 100vh;
  background-color: #ffffff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero-section {
  padding: 80px 0;
}

.hero-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

.text-content {
  padding-right: 20px;
  padding-left: 0;
  max-width: 100%;
  box-sizing: border-box;
}

@media (max-width: 968px) {
  .text-content {
    padding-right: 0;
    padding-left: 0;
    padding: 0 10px;
  }

  .description,
  .vision-block p,
  .mission-block p {
    max-width: 100%;
    box-sizing: border-box;
  }
}

h1 {
  color: #666;
  font-size: 1.2rem;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.headline {
  color: #333;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 30px;
  background: linear-gradient(to right, #2d2d2d, #666);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
}

.description {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: left;
}

.image-wrapper {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
}

.shape-overlay {
  position: absolute;
  bottom: -10%;
  right: -10%;
  width: 120%;
  height: 120%;
  background-color: #ffffff;
  border-radius: 50%;
  z-index: -1;
}

.dots-overlay {
  position: absolute;
  bottom: -20%;
  right: -20%;
  width: 200px;
  height: 200px;
  background-image: radial-gradient(circle, #666 2px, transparent 2px);
  background-size: 15px 15px;
  opacity: 0.3;
}

.vision-mission-section {
  padding: 80px 0;
  background-color: #f8f8f8;
}

.vision-mission-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

.vision-block, .mission-block {
  margin-bottom: 40px;
}

.vision-block h3, .mission-block h3 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px;
}

.vision-block p, .mission-block p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 968px) {
  .hero-content,
  .vision-mission-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .headline {
    font-size: 2.5rem;
  }

  .text-content {
    padding-right: 0;
  }

  .image-wrapper {
    max-width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .headline {
    font-size: 2rem;
  }

  .vision-block h3, .mission-block h3 {
    font-size: 1.8rem;
  }
}

.process-section {
  padding: 80px 0;
  background-color: #fff;
  overflow: hidden;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 60px;
}

.process-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin: 0 auto;
  position: relative;
  padding: 40px 20px;
  max-width: 1400px;
  width: 100%;
}

.process-item {
  flex: none;
  padding: 15px;
  background: white;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 50px;
}

.process-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  position: relative;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.process-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
}

/* Update responsive design */
@media (max-width: 1200px) {
  .process-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .process-grid {
    grid-template-columns: 1fr;
    gap: 60px;
  }

  .process-item:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -40px;
    width: 2px;
    height: 40px;
    background: #007bff;
    transform: translateX(-50%);
    z-index: 1;
  }

  .process-item:not(:last-child)::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -48px;
    width: 12px;
    height: 12px;
    border-left: 2px solid #007bff;
    border-bottom: 2px solid #007bff;
    transform: translateX(-50%) rotate(-45deg);
    z-index: 2;
    background-color: transparent;
  }
}

@media (max-width: 480px) {
  .process-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

.process-item:not(:last-child)::before {
  display: block;
}

/* Update the line and add SVG arrow */
.process-item:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -50px;
  top: 50%;
  width: 50px;
  height: 6px;
  background: #007bff;
  transform: translateY(-50%);
  z-index: 1;
}

.process-item:not(:last-child)::before {
  content: '';
  position: absolute;
  right: -35px;
  top: 50%;
  width: 16px;
  height: 16px;
  border-top: 6px solid #007bff;
  border-right: 6px solid #007bff;
  transform: translateY(-50%) rotate(45deg);
  z-index: 2;
  background-color: transparent;
}

.process-item:hover .process-icon {
  transform: translateY(-5px);
}

.process-item h3 {
  font-size: 0.9rem;
  margin-top: 15px;
  color: #333;
  max-width: 140px;
  text-align: center;
}

/* Gradient colors for different items */
.process-item:nth-child(odd) .process-icon {
  background: linear-gradient(135deg, #ededed, #82a6dc);
}

.process-item:nth-child(even) .process-icon {
  background: linear-gradient(135deg, #ededed, #3376da);
}

/* Update responsive design */
@media (max-width: 1400px) {
  .process-grid {
    justify-content: center;
    overflow-x: auto;
    padding: 40px 30px;
    margin: 0 auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    gap: 70px;
  }

  .process-item:not(:last-child)::after {
    right: -70px;
    width: 70px;
  }
}

@media (max-width: 768px) {
  .process-grid {
    gap: 60px;
  }

  .process-item {
    flex: 0 0 120px;
  }

  .process-icon {
    width: 80px;
    height: 80px;
  }

  .process-item:not(:last-child)::after {
    right: -60px;
    width: 60px;
    background-size: 10px; /* Slightly smaller arrow on mobile */
  }
}

/* Alternative arrow style if the Unicode arrow doesn't display well */
/* Uncomment this and comment out the above if needed */
/*
.process-grid::after {
  content: '';
  position: absolute;
  right: 45px;
  top: 50%;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 4px 4px 0 0;
  border-color: #3376da;
  transform: translateY(-50%) rotate(45deg);
  z-index: 2;
}
*/

/* Add specific styles for methodology section */
.methodology-grid {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Changed from center to align items at the top */
  gap: 60px;
  margin: 0 auto;
  padding: 40px 60px;
  max-width: 1200px;
  width: 100%;
}

.methodology-item {
  flex: 0 0 300px; /* Increased from 140px */
  padding: 20px;
  background: white;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.methodology-icon {
  width: 180px; /* Increased from 100px */
  height: 180px; /* Increased from 100px */
  margin-bottom: 25px;
  position: relative;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.methodology-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 20px;
}

.methodology-item h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.methodology-item p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  max-width: 280px;
  margin: 0 auto;
}

/* Responsive design for methodology section */
@media (max-width: 1200px) {
  .methodology-grid {
    gap: 40px;
    padding: 30px;
  }
  
  .methodology-item {
    flex: 0 0 280px;
  }
}

@media (max-width: 768px) {
  .methodology-grid {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .methodology-item {
    flex: 0 0 auto;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .team-grid {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .team-item {
    max-width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }
}

/* Styles for Why Choose Us section */
.team-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1400px; /* Increased from 1200px */
  padding: 40px 20px; /* Increased padding */
}

.team-item {
  width: 100%;
  max-width: 1100px; /* Increased from 900px */
  margin: 0 auto;
}

.team-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 12px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.12);
}

/* Update responsive breakpoints */
@media (max-width: 1200px) {
  .team-item {
    max-width: 900px;
  }
}

@media (max-width: 992px) {
  .team-item {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .team-grid {
    padding: 20px;
  }
  
  .team-item {
    max-width: 100%;
    padding: 0 15px;
  }
}