.home section {
  padding: 80px 0;
}

.container {
  padding: 0 1rem;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.hero-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 80px 20px 40px;
  align-items: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .hero-content {
    grid-template-columns: 1fr;
    padding: 40px 20px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .hero-text {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hero-text h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  .hero-text .hero-description {
    text-align: center;
  }

  .hero-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .about-content {
    text-align: center;
  }

  .about-images {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-image {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
}

.about-content {
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .hero-content,
  .about-content,
  .message-container {
    padding: 1rem;
    width: 100%;
  }

  .about-images {
    width: 100%;
    padding: 0 1rem;
  }

  .about-image {
    width: 100%;
    max-width: 100%;
  }

  .leader-modal {
    width: 100%;
    padding: 1rem;
  }

  .leader-image {
    width: 100%;
    max-width: 100%;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .service-card {
    width: 100%;
  }

  .message-container {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
  }

  .director-image {
    position: static;
    width: 100%;
    max-width: 250px;
    margin: 0 auto 2rem;
  }

  .message-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }

  .principle {
    width: 100%;
    box-sizing: border-box;
    margin: 1rem 0;
  }

  .testimonials-section {
    padding: 2rem 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  .testimonial-card {
    width: 100%;
    margin: 1rem 0;
    box-sizing: border-box;
  }

  .testimonial-content {
    padding: 1rem;
  }
}

/* Hero Section */
.hero {
  position: relative;
  min-height: auto;
  background: #fff;
  overflow: hidden;
  padding: 0 !important;
  padding-top: 80px; /* Account for navbar */
}

.hero-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 80px 50px 40px;
  align-items: center;
}

.hero-text {
  padding-right: 40px;
}

.logo-small {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #007bff;
  margin-bottom: 20px;
}

.hero-text h1 {
  color: #333;
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 30px;
  font-weight: 700;
}

.hero-description {
  color: #666;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 40px;
}

.hero-buttons {
  display: flex;
  gap: 20px;
}

.btn {
  padding: 15px 30px;
  border-radius: 8px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: transparent;
  color: #333;
  border: 2px solid #007bff;
}

.btn-secondary:hover {
  background-color: #f8f9fa;
  transform: translateY(-2px);
}

.hero-images {
  position: relative;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  position: relative;
  margin-bottom: 0;
}

.image-item {
  border-radius: 20px;
  overflow: hidden;
  aspect-ratio: 1;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  display: block;
  text-decoration: none;
  color: inherit;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  padding: 20px;
  transition: opacity 0.3s ease;
}

.image-overlay h3 {
  color: white;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.image-item:hover .image-overlay {
  opacity: 1;
}

.image-item:hover img {
  transform: scale(1.05);
}

/* Add some animation to the grid items */
.image-item:nth-child(1) {
  transform: translateY(-20px);
}

.image-item:nth-child(4) {
  transform: translateY(20px);
}

/* Responsive design */
@media (max-width: 1200px) {
  .hero-text h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 992px) {
  .hero-content {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 60px 30px;
  }

  .hero-text {
    padding-right: 0;
  }

  .hero-buttons {
    justify-content: center;
  }

  .image-grid {
    max-width: 600px;
    margin: 0 auto;
  }

  .image-item:nth-child(1),
  .image-item:nth-child(4) {
    transform: none;
  }
}

@media (max-width: 480px) {
  .hero-text h1 {
    font-size: 2.5rem;
  }

  .hero-buttons {
    flex-direction: column;
  }

  .image-grid {
    gap: 10px;
  }
}

/* Style for about section inside hero */
.about-section {
  background-color: #fff;
  padding: 40px 0 80px;
  margin-top: 0;
}

/* Mission & Vision Section */
.mission-vision {
  background-color: #fff;
}

/* Services Section */
.services {
  background: #f8f9fa;
  padding: 80px 0;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 60px;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: #007bff;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 40px;
}

.service-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.service-image {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-card:hover .service-image img {
  transform: scale(1.1);
}

.service-content {
  padding: 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.service-content h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: 600;
}

.service-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
  flex-grow: 1;
}

.learn-more {
  color: #007bff;
  font-weight: 500;
  display: inline-block;
  transition: transform 0.3s ease;
}

.service-card:hover .learn-more {
  transform: translateX(10px);
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 0 15px;
    margin-top: 30px;
  }

  .service-card {
    width: 100%;
    max-width: none;
    margin: 0;
  }

  .service-image {
    height: 200px;
  }

  .service-content {
    padding: 20px;
  }

  .service-content h3 {
    font-size: 1.3rem;
    margin-bottom: 12px;
  }

  .service-content p {
    font-size: 0.95rem;
    margin-bottom: 15px;
  }
}

/* Leadership Team Section */
.leadership {
  background: #f8f9fa;
  padding: 80px 0;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 40px;
}

@media (max-width: 1200px) {
  .team-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .team-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin: 0;
  }

  .team-member {
    width: 100%;
    margin: 0;
  }
}

.team-member {
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
}

.member-image {
  width: 200px;
  height: 200px;
  margin: 0 auto 20px;
  border-radius: 50%;
  overflow: hidden;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-member h3 {
  color: #333;
  margin: 10px 0 5px;
  font-size: 20px;
}

.team-member h4 {
  color: #666;
  margin: 0 0 10px;
  font-size: 16px;
}

.team-member p {
  color: #777;
  font-size: 14px;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .team-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .leadership {
    padding: 40px 1rem;
  }

  .team-grid {
    grid-template-columns: 1fr;
    gap: 25px;
    margin-top: 30px;
  }

  .team-member {
    padding: 15px;
    max-width: 100%;
  }

  .member-image {
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
  }

  .team-member h3 {
    font-size: 1.2rem;
    margin: 8px 0 4px;
  }

  .team-member h4 {
    font-size: 0.9rem;
    margin: 0 0 8px;
  }

  .team-member p {
    font-size: 0.85rem;
    line-height: 1.5;
  }

  .leader-modal {
    padding: 1.5rem;
  }

  .leader-modal-content {
    flex-direction: column;
    gap: 1.5rem;
  }

  .leader-image {
    max-width: 250px;
    margin: 0 auto;
  }

  .leader-info h2 {
    font-size: 1.5rem;
  }

  .leader-info h3 {
    font-size: 1.1rem;
  }

  .leader-description p {
    font-size: 0.95rem;
    line-height: 1.5;
  }
}

/* Update about-content styles */
.about-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
}

.about-text {
  padding-right: 40px;
}

.about-text h2 {
  font-size: 3.5rem;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #666;
  margin-bottom: 30px;
}

.read-more-btn {
  display: inline-block;
  padding: 12px 30px;
  background-color: #e8f3ff;
  color: #007bff;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.read-more-btn:hover {
  background-color: #007bff;
  color: white;
  transform: translateY(-2px);
}

.stat-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 40px;
}

.stat-item {
  text-align: left;
}

.stat-item h3 {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 10px;
  font-weight: 700;
  transition: all 0.3s ease;
}

/* Optional: Add a subtle animation when the numbers become visible */
.stat-item h3.is-visible {
  transform: scale(1.1);
}

.stat-item p {
  color: #666;
  font-size: 1rem;
}

.about-images {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
  overflow: hidden;
}

.about-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.about-image:hover {
  transform: scale(1.05);
}

/* Make some images span multiple columns */
.about-images img:nth-child(1) {
  grid-column: span 2;
}

.about-images img:nth-child(2) {
  grid-column: span 2;
}

.about-images img:nth-child(3) {
  grid-column: span 2;
}

.about-images img:nth-child(4) {
  grid-column: span 3;
}

.about-images img:nth-child(5) {
  grid-column: span 3;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .about-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .about-text {
    padding-right: 0;
  }

  .about-text h2 {
    font-size: 3rem;
  }

  .stat-grid {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .about-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .about-text p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .stat-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
  }

  .stat-item h3 {
    font-size: 2rem;
  }

  .stat-item p {
    font-size: 0.9rem;
  }

  .about-images {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .about-image {
    height: 180px;
  }

  .about-images img:nth-child(n) {
    grid-column: span 1;
  }

  .read-more-btn {
    padding: 10px 25px;
    font-size: 0.9rem;
  }

  .about-section {
    padding: 30px 1rem 60px;
  }

  .about-content {
    gap: 30px;
  }
}

.expand-btn {
  padding: 0.5rem 1rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background 0.2s;
}

.expand-btn:hover {
  background: #0056b3;
}

.leader-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 2rem;
}

.leader-modal {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 2rem;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
}

.leader-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
}

.leader-image {
  display: flex;
  justify-content: center;
  flex: 0 0 300px;
  display: flex;
  justify-content: center;
}

.leader-image img {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
}

.leader-info {
  flex: 1;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.leader-info h2 {
  margin: 0 0 0.5rem;
  color: #333;
}

.leader-info h3 {
  margin: 0 0 1.5rem;
  color: #666;
}

.leader-description p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .leader-modal-content {
    flex-direction: column;
  }

  .leader-image {
    flex: 0 0 auto;
    max-width: 300px;
    margin: 0 auto;
  }
}

.about-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.about-logo img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* Update responsive design */
@media (max-width: 1200px) {
  .about-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .about-logo {
    padding: 1rem;
  }

  .about-logo img {
    max-width: 300px;
  }
}

.directors-message {
  background: #fff;
  padding: 80px 0;
  margin-top: 0; /* Remove extra margin */
}

.message-container {
  display: flex;
  gap: 3rem;
  align-items: flex-start;
  margin-top: 2rem;
}

.director-image {
  flex: 0 0 250px;
  text-align: center;
  position: sticky;
  top: 100px;
  z-index: 1;
}

.director-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 3px solid #fff;
}

.director-image h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.3rem;
}

.director-title {
  color: #666;
  font-size: 1rem;
}

.message-content {
  flex: 1;
}

.message-content .intro {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  color: #333;
}

.serve-principles {
  margin: 1.5rem 0;
}

.principle {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.principle-item {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.principle-item:last-child {
  border-bottom: none;
}

.principle-item strong {
  color: #12378f;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.principle-item p {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
}

.message-footer {
  margin-top: 2rem;
}

.conclusion, .implementation {
  font-size: 1rem;
  line-height: 1.5;
  color: #444;
  margin-bottom: 1.5rem;
}

@media (max-width: 1024px) {
  .message-container {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }

  .director-image {
    position: static;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 250px;
  }

  .message-content {
    text-align: left;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .directors-message {
    padding: 40px 1rem;
  }

  .director-photo {
    width: 150px;
    height: 150px;
  }

  .director-image h3 {
    font-size: 1.3rem;
  }

  .director-title {
    font-size: 0.9rem;
  }

  .message-content .intro {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .principle {
    padding: 1rem;
  }

  .principle-item {
    padding: 0.8rem;
  }

  .principle-item strong {
    font-size: 1rem;
  }

  .principle-item p {
    font-size: 0.9rem;
  }

  .conclusion, .implementation {
    font-size: 0.95rem;
  }
}

@media (max-width: 1024px) {
  .directors-message {
    margin-top: 60px;
  }

  .director-image {
    position: static;
    margin-bottom: 2rem;
  }
}

/* Remove any extra margins between sections */
section {
  margin: 0;
}

.serve-link {
  color: #3376da;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.serve-link:hover {
  color: #2255a5;
  text-decoration: underline;
}
