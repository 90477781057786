.building-technologies {
  padding: 80px 0;
}

.technologies-content {
  margin-top: 40px;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

p {
  color: #666;
  line-height: 1.6;
  font-size: 18px;
}

.products-container {
  background-color: #fff;
}

.hero-section {
  width: 100%;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  text-align: center;
}

.hero-section h2 {
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 40px;
  line-height: 1.2;
}

.hero-section img {
  width: 100%;
  max-width: 1200px;
  height: auto;
  display: block;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.product-section {
  padding: 80px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.product-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

.product-text {
  padding-right: 20px;
}

.product-text h2 {
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 25px;
  line-height: 1.2;
}

.product-description {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

.feature-card {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card h3 {
  color: #333;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

.feature-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

.product-image-wrapper {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.product-image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  z-index: 1;
}

.shape-overlay {
  position: absolute;
  bottom: -10%;
  right: -10%;
  width: 120%;
  height: 120%;
  background-color: #f8f9fa;
  border-radius: 20px;
  z-index: 0;
}

.dots-overlay {
  position: absolute;
  bottom: -20%;
  right: -20%;
  width: 200px;
  height: 200px;
  background-image: radial-gradient(circle, #666 2px, transparent 2px);
  background-size: 15px 15px;
  opacity: 0.3;
  z-index: 2;
}

/* Responsive Design */
@media (max-width: 992px) {
  .product-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .product-text {
    padding-right: 0;
    text-align: center;
  }

  .product-text h2 {
    font-size: 2.3rem;
  }

  .features-grid {
    max-width: 600px;
    margin: 30px auto 0;
  }

  .product-image-wrapper {
    max-width: 80%;
    margin: 0 auto;
  }

  .hero-section img {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .product-text h2 {
    font-size: 2rem;
  }

  .feature-card {
    padding: 20px;
  }

  .hero-section {
    padding: 20px 15px;
  }
  
  .hero-section h2 {
    font-size: 2.3rem;
    margin-bottom: 30px;
  }

  .hero-section img {
    max-width: 100%;
  }
} 